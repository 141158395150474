<template>
    <div class="relative w-full h-screen absolute inset-0 flex ">
      <canvas ref="canvasRef" :width="dimensions.width" :height="dimensions.height" class="absolute inset-0">

      </canvas>
  
    </div>
  </template>
  
  <script setup>
import { ref, onMounted, onBeforeUnmount, reactive } from 'vue'

class Particle {
  constructor(x, y, canvas) {
    this.x = x
    this.y = y
    this.canvas = canvas
    this.size = Math.random() * 5 + 3
    this.speedX = Math.random() * 3 - 1.5
    this.speedY = Math.random() * 3 - 1.5
    this.color = Math.random() > 0.5 ? '#085ec5' : '#D30C37'
    this.alpha = Math.random() * 0.5 + 0.5
    this.targetAlpha = this.alpha
    this.isPopping = false
    this.popProgress = 0
  }

  update() {
    this.x += this.speedX
    this.y += this.speedY

    // Bounce off walls
    if (this.x <= 0 || this.x >= this.canvas.width) this.speedX *= -0.5
    if (this.y <= 0 || this.y >= this.canvas.height) this.speedY *= -0.5

    // Update alpha and size during pop
    if (this.isPopping) {
      this.popProgress += 0.05
      this.alpha = Math.max(0, this.alpha - 0.05)
      this.size += 0.5
      if (this.popProgress >= 1) {
        this.alpha = 0
      }
    } else {
      // Normal alpha behavior
      if (this.alpha < this.targetAlpha) {
        this.alpha += 0.02
      } else if (this.alpha > this.targetAlpha) {
        this.alpha -= 0.02
      }
    }
  }

  draw(ctx) {
    ctx.beginPath()
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2)
    ctx.fillStyle = this.color
    ctx.globalAlpha = this.alpha
    ctx.fill()
    ctx.globalAlpha = 1
  }

  pop() {
    if (!this.isPopping) {
      this.isPopping = true
      this.popProgress = 0
    }
  }

  isFullyPopped() {
    return this.isPopping && this.alpha <= 0
  }
}

const canvasRef = ref(null)
const dimensions = reactive({
  width: window.innerWidth,
  height: window.innerHeight
})
let particles = []
let animationFrame = null

const updateDimensions = () => {
  dimensions.width = window.innerWidth
  dimensions.height = window.innerHeight
}

const initializeParticles = () => {
  particles = []
  for (let i = 0; i < 30; i++) {
    particles.push(
      new Particle(
        Math.random() * dimensions.width,
        Math.random() * dimensions.height,
        canvasRef.value
      )
    )
  }
}

const handleMouseMove = (event) => {
  const canvas = canvasRef.value
  const rect = canvas.getBoundingClientRect()
  const x = event.clientX - rect.left
  const y = event.clientY - rect.top

  particles.forEach(particle => {
    const dx = particle.x - x
    const dy = particle.y - y
    const distance = Math.sqrt(dx * dx + dy * dy)

    if (distance < 70) { // Increased trigger distance
      particle.pop()
    }
  })
}

const animate = () => {
  const canvas = canvasRef.value
  const ctx = canvas.getContext('2d')

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  particles.forEach((particle, index) => {
    particle.update()
    particle.draw(ctx)

    if (particle.isFullyPopped()) {
      // Create a new particle with a fade-in effect
      const newParticle = new Particle(
        Math.random() * dimensions.width,
        Math.random() * dimensions.height,
        canvas
      )
      newParticle.alpha = 0 // Start fully transparent
      newParticle.targetAlpha = Math.random() * 0.5 + 0.5 // Normal target alpha
      particles[index] = newParticle
    }
  })

  animationFrame = requestAnimationFrame(animate)
}

onMounted(() => {
  updateDimensions()
  window.addEventListener('resize', updateDimensions)
  initializeParticles()
  animate()

  canvasRef.value.addEventListener('mousemove', handleMouseMove)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateDimensions)
  if (canvasRef.value) {
    canvasRef.value.removeEventListener('mousemove', handleMouseMove)
  }
  if (animationFrame) {
    cancelAnimationFrame(animationFrame)
  }
})
</script>
  
  <style scoped>
  .relative {
    position: relative;
    z-index: 0;
  }
  .absolute {
    position: absolute;
  }
  .inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .flex {
    display: flex;
  }
  .items-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }
  .text-4xl {
    font-size: 2.25rem;
  }
  .font-bold {
    font-weight: 700;
  }
  .w-full {
    width: 100%;
  }
  .h-screen {
    height: 100vh;
  }
  </style>